import React from "react"
import SVG from "react-inlinesvg"
import { useDispatch } from "react-redux"
import { useMediaQuery } from "react-responsive"

import DropDown from "@components/DropDown"
import { AppActions } from "@store/App/actions"
import iconArrowRight from "@static/svg/arrow-right.svg"

import style from "./DoTogetherPage.scss"

interface IDoTogehterPageProps {
  onConnectClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const DoTogehterPage: React.FunctionComponent<IDoTogehterPageProps> = (props) => {
  const { onConnectClick } = props
  const dispatch = useDispatch()
  const isTabletM = useMediaQuery({ query: `(max-width: ${style.tabletM})` })

  const onToDoHowDropDownChange = (selectedOption: string) => {
    dispatch(AppActions.setToDoHow(selectedOption))
  }

  const onToDoWhatDropDownChange = (selectedOption: string) => {
    dispatch(AppActions.setToDoWhat(selectedOption))
  }

  return (
    <div className={style.doTogetherPage}>
      <div className={style.pageContentLeft}>
        <h1 style={{ marginBottom: "3rem" }}>
          what will we<br/><span className={style.titleSub}>do together</span>
        </h1>
        <p>
          I can translate your business needs into the language of technology. Moreover, I can work with you as a
        </p>
        <div className={style.dropDownGroup}>
          <DropDown
            className={style.dropDown}
            options={["short term", "long term"]}
            onChange={onToDoHowDropDownChange}
          />
          <DropDown
            className={style.dropDown}
            options={["developer", "agile coach", "devops", "tech lead"]}
            onChange={onToDoWhatDropDownChange}
          />
        </div>
        <p>
          and manage a whole lean product development lifecycle from prototyping to development.
          I can guide and scale-up your team, but I also  like to get my hands dirty: I love to do creative
          designs, write frontend or backend code and to design server infrastructures.
        </p>
        {
          !isTabletM
            ?
              <div className={style.linkButton}>
                <h2>want to know more?</h2>
                <div className={style.arrowTextPlaceholder}>
                  <h2>
                    let us connect
                  </h2>
                  <div className={style.arrow} onClick={onConnectClick}>
                    <SVG src={iconArrowRight}/>
                  </div>
                </div>
              </div>
            : null
        }
      </div>
      <div className={style.pageContentRight}>
        <div className={`${style.pageContentRightCircle} ${style.pageContentRightCircleOne}`}>
          <span className={style.circleText}>coder</span>
        </div>
        <div className={`${style.pageContentRightCircle} ${style.pageContentRightCircleTwo}`}>
          <span className={style.circleText}>agile coach</span>
        </div>
        <div className={`${style.pageContentRightCircle} ${style.pageContentRightCircleThree}`}>
          <span className={style.circleText}>entrepreneur</span>
        </div>
      </div>
    </div>
  )
}

export default DoTogehterPage
