import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"

import iconArrowDown from "../../static/svg/arrow-down.svg"

import componentStyle from "./DropDown.scss"

enum InputTypes {
  TEXT = "text",
}

interface IDropDownProps {
  className?: string
  options: string[]
  onChange?: (e: string) => void
}

const DropDown: React.FunctionComponent<IDropDownProps> = (props) => {
  const { className, onChange, options } = props
  const [dropDownClicked, setDropDownClicked] = useState<boolean>(false)
  const [dropDownOptions, setDropDownOptions] = useState<JSX.Element[]>([])
  const [styleOptionsContainer, setStyleOptionsContainer] = useState<object>({})
  const [selectedOption, setSelectedOption] = useState<string>("")

  useEffect(() => {
    const arrayOfOptions: JSX.Element[] = []
    options.forEach((option, optIndex) => {
      arrayOfOptions.push(
        <li
          key={optIndex}
          style={styleOptionsContainer}
          className={componentStyle.option}
          onClick={onOptionClick}
        >
          {option}
        </li>
      )
    })
    setDropDownOptions(arrayOfOptions)
  }, [options, styleOptionsContainer])

  useEffect(() => {
    if (dropDownClicked) {
      setStyleOptionsContainer({
        visibility: "visible",
        opacity: 1,
      })
    } else {
      setStyleOptionsContainer({
        visibility: "hidden",
        opacity: 0,
      })
    }
  }, [dropDownClicked])

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])  //eslint-disable-line

  const onArrowClick = () => setDropDownClicked(!dropDownClicked)

  const onOptionClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setSelectedOption(e.currentTarget.textContent)
    setDropDownClicked(false)
  }

  return (
    <div className={`${componentStyle.dropDown} ${className}`}>
      <div className={componentStyle.textArea}>
        <input
          className={componentStyle.textAreaInput}
          type={InputTypes.TEXT}
          defaultValue={selectedOption}
        />
        <ul style={styleOptionsContainer} className={componentStyle.optionsContainer}>
          {dropDownOptions}
        </ul>
      </div>
      <div className={componentStyle.arrow} onClick={onArrowClick}>
        <SVG viewBox={"0 0 35 70"} width="5rem" height="2rem" src={iconArrowDown}/>
      </div>
    </div>
  )
}

export default DropDown
